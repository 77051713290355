<template>
    <div class="selectLanguage">
        <div
            class="input--clear btn-text selectLanguage__langSelect"
            @click="() => (this.isListActive = !this.isListActive)"
        >
            {{ selectedLanguage.language }}
            <img
                :class="
                    isListActive ? 'selectLanguage__imgRotate' : 'selectLanguage__img'
                "
                src="@/assets/img/common/blueArrow.svg"
            />
        </div>

        <div v-if="this.isListActive" class="selectLanguageOptions">
            <div
                v-for="(item, index) in options"
                :key="index"
                :class="
                    item.language === selectedLanguage.language
                        ? 'text2 colorWhite selectLanguageOption selectLanguageOption--active'
                        : 'text2 colorBlack selectLanguageOption'
                "
                @click="() => hideMenu(item.language)"
            >
                <span class="selectLanguageOption__text">{{ item.language }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import Button from "@/views/components/Button/Button";
export default {
    name: "SelectLanguage",

    components: {
        Button,
    },

    data() {
        return {
            isListActive: false,
            selectedLanguage: {},
        };
    },

    props: {
        defaultLanguage: String,
        options: [
            {
                language: String,
            },
        ],
    },

    methods: {
        hideMenu(language) {
            this.selectedLanguage = this.options.find(
                (item) => item.language === language
            );
            this.isListActive = false;
        },
    },

    mounted() {
        this.selectedLanguage = this.options.find(
            (item) => item.language === this.defaultLanguage
        );
    },
};
</script>

<style lang="scss" scoped>
@import "SelectLanguage.scss";
</style>
