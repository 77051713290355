<template>
    <div class="certificateBox">
        <div class="certificate">
            <div class="certificateLeftShadow"></div>
            <div class="certificateItem">
                <p class="bold certificateItem__title">Сертификат</p>
                <p class="h6 colorGray certificateItem__subtitle">
                    Подтверждает, что
                </p>
                <p class="title2 colorDenim certificateItem__name">
                    {{ certificateCardName }}
                </p>
                <p class="h6 colorGray certificateItem__description">
                    Прошел(ла) дистанционный курс
                </p>
                <p class="text2 text2--2 certificateItem__nameCourse">
                    {{ certificateCardCourse }}
                </p>
                <p class="colorLynch text5 certificateItem__date">
                    {{ certificateCardDate }}
                </p>
                <div class="certificateItem__footer">
                    <p class="text5 colorGray certificateItem__footer_logoBox">
                        <img
                            class="certificateItem__footer_logo"
                            src="@/assets/img/common/logo.svg"
                            alt="logo"
                        />
                        <span>
                            ОБРАЗОВАТЕЛЬНЫЙ ЦЕНТР <br />
                            АЛЕКСАНДРА ГЕРЧИКА
                        </span>
                    </p>
                    <img
                        src="@/assets/img/pages/certificate/signature.svg"
                        alt="signature"
                        class="certificateItem__footer_signature"
                    />
                </div>
            </div>
            <div class="certificateRightShadow"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: "certificateCard",
    components: {},
    props: {
        certificateCardName: String,
        certificateCardCourse: String,
        certificateCardDate: String,
    },
    methods: {},
};
</script>

<style lang="scss" scoped>
@import "certificateCard.scss";
</style>
