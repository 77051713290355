<template>
    <div class="shareBlock">
        <p class="shareBlock__title text3 colorLynch alignCenter">
            Поделитесь достижением с друзьями:
        </p>

        <div class="shareBlock__socials">
            <ShareNetwork
                network="facebook"
                url="https://edu.gerchik.com/"
                :title="shareText"
                :description="shareText"
                :quote="shareText"
            >
                <p class="Social Social--fb shareBlock__shareIcon"></p>
            </ShareNetwork>

            <ShareNetwork
                network="vk"
                url="https://edu.gerchik.com/"
                :title="shareText"
                :description="shareText"
                :quote="shareText"
                :media="shareIcon || ''"
            >
                <p class="Social Social--vk shareBlock__shareIcon"></p>
            </ShareNetwork>

            <ShareNetwork
                network="telegram"
                url="https://edu.gerchik.com/"
                :title="shareText"
                :quote="shareText"
            >
                <p class="Social Social--tg shareBlock__shareIcon"></p>
            </ShareNetwork>
        </div>
    </div>
</template>

<script>
export default {
    name: "ShareBlock",

    components: {

    },

    data() {
        return {

        };
    },

    beforeMount() {

    },

    props: {
        shareText: String,
        shareIcon: Image
    },

    methods: {

    },
};
</script>

<style lang="scss" scoped>
@import "ShareBlock.scss";
</style>
