<template>
    <Layout>
        <div class="pageSection">
            <SideBar />

            <div class="cetificatePage">
                <div class="cetificatePageWrapper">
                    <div class="cetificatePage__description">
                        <p class="h1">{{ title }}</p>
                        <p class="colorFiord cetificatePage__subtitle">{{ text }}</p>
                        <button
                            v-if="certificate.result === 1"
                            class="btn-text cetificatePage__button"
                        >
                            <span @click="downloadPDF">Скачать</span>
                            <!--<span>-->
                            <!--<SelectLanguage-->
                            <!--defaultLanguage="RU"-->
                            <!--:options="programsSelect"-->
                            <!--/>-->
                            <!--</span>-->
                        </button>
                        <Button
                            class="cetificatePage__button-2"
                            title="Вернутся к обучению"
                            :isLink="true"
                            :link="'/' + $route.params.slug + '/study'"
                        />

                        <div
                            v-if="certificate.result === 1"
                            class="cetificatePage__share"
                        >
                            <ShareBlock :shareText="shareText" />
                        </div>
                    </div>

                    <div
                        :class="
                            certificate.result === 1
                                ? 'certificateBox'
                                : 'certificateBox certificateBox--disabled'
                        "
                    >
                        <vue-html2pdf
                            v-if="certificate"
                            :show-layout="false"
                            :float-layout="true"
                            :enable-download="true"
                            :preview-modal="false"
                            :paginate-elements-by-height="1400"
                            :filename="'certificate_' + certificate.slug"
                            :pdf-quality="2"
                            :manual-pagination="false"
                            pdf-format="a6"
                            pdf-orientation="landscape"
                            pdf-content-width="800px"
                            ref="html2Pdf"
                            :html-to-pdf-options="{
                                margin: [5, 4],
                                filename: `certificate_${this.certificate.slug}.pdf`,
                                image: {
                                    type: 'jpeg',
                                    quality: 1,
                                },

                                html2canvas: {
                                    scale: 5,
                                },
                                jsPDF: {
                                    format: 'a6',
                                    orientation: 'landscape',
                                },
                            }"
                        >
                            <div class="certificate certificate--pdf" slot="pdf-content">
                                <CertificateCard
                                    :certificateCardName="
                                        certificate.firstName + ' ' + certificate.lastName
                                    "
                                    :certificateCardCourse="certificate.courseName"
                                    :certificateCardDate="certificate.date2"
                                />
                            </div>
                        </vue-html2pdf>
                        <div class="certificate">
                            <CertificateCard
                                :certificateCardName="
                                    certificate.firstName + ' ' + certificate.lastName
                                "
                                :certificateCardCourse="certificate.courseName"
                                :certificateCardDate="certificate.date2"
                            />
                        </div>
                        <div class="backgroundZircon cetificateRightBg"></div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>
import Layout from "@/views/layouts/Layout";
import SideBar from "@/views/partials/sideBar/sideBar";
import CertificateCard from "@/views/components/CertificateCard/CertificateCard";
import SelectLanguage from "@/views/components/SelectLanguage/SelectLanguage";
import Button from "@/views/components/Button/Button";
import ShareBlock from "@/views/components/ShareBlock/ShareBlock";

import VueHtml2pdf from "vue-html2pdf";

export default {
    name: "certificate",
    components: {
        Layout,
        SideBar,
        CertificateCard,
        SelectLanguage,
        Button,
        ShareBlock,
        VueHtml2pdf,
    },

    async beforeMount() {
        this.updateCertificateStore();
    },

    watch: {
        "$route.params.slug": function() {
            this.updateCertificateStore();
        },
    },

    computed: {
        certificate: function() {
            return this.$store.getters.getCertificate;
        },

        courseName() {
            return (
                (this.$store.getters.getMember &&
                    this.$store.getters.getMember.data &&
                    this.$store.getters.getMember.data.courseName) ||
                ""
            );
        },

        shareText() {
            return `Я успешно завершил/а курс ${this.courseName}` || "";
        },
    },

    data() {
        return {
            title: "",
            text: "",
            programsSelect: [
                {
                    language: "RU",
                },
                {
                    language: "EN",
                },
            ],
        };
    },

    methods: {
        async updateCertificateStore() {
            const getCertificate = await this.axios.get(
                "/programs/" + this.$route.params.slug + "/study/certificate"
            );

            if (getCertificate && getCertificate.data && getCertificate.data.result) {
                await this.$store.dispatch("setCertificate", getCertificate.data);

                this.title = "Поздравляем!";
                this.text =
                    "Вы прошли непростой путь становления трейдером. Со времени старта прошло уже немало времени, но Вы дошли до конца. Поэтому торжественно вручаем Вам сертификат об окончании обучения!";

                await this.axios.post(
                    "/programs/" +
                        this.$route.params.slug +
                        "/study/dowloaded-certificate"
                );
            } else {
                if (
                    getCertificate &&
                    getCertificate.data &&
                    getCertificate.data.code === "success"
                ) {
                    if (getCertificate.data.code === "success") {
                        await this.$store.dispatch("setCertificate", getCertificate.data);

                        this.title = "Внимание!";
                        this.text =
                            "Для того, чтобы Вам был доступен сертификат на скачивание - необходимо пройти все уроки и выполнить все задания в разделе “Обучение”.";
                    }
                } else {
                    await this.$store.dispatch("clearCertificate");

                    console.error(
                        getCertificate.data.code + ": " + getCertificate.data.error
                    );
                    await this.$store.dispatch("setAlert", {
                        alert: {
                            isActive: true,
                            type: "error",
                            text: "Пользователь не найден",
                        },
                    });

                    await this.$router.push("/" + this.$route.params.slug);
                }
            }
        },

        downloadPDF() {
            this.$refs.html2Pdf.generatePdf();
        },
    },
};
</script>

<style lang="scss" scoped>
@import "certificate.scss";
</style>
