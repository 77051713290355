var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('div',{staticClass:"pageSection"},[_c('SideBar'),_c('div',{staticClass:"cetificatePage"},[_c('div',{staticClass:"cetificatePageWrapper"},[_c('div',{staticClass:"cetificatePage__description"},[_c('p',{staticClass:"h1"},[_vm._v(_vm._s(_vm.title))]),_c('p',{staticClass:"colorFiord cetificatePage__subtitle"},[_vm._v(_vm._s(_vm.text))]),(_vm.certificate.result === 1)?_c('button',{staticClass:"btn-text cetificatePage__button"},[_c('span',{on:{"click":_vm.downloadPDF}},[_vm._v("Скачать")])]):_vm._e(),_c('Button',{staticClass:"cetificatePage__button-2",attrs:{"title":"Вернутся к обучению","isLink":true,"link":'/' + _vm.$route.params.slug + '/study'}}),(_vm.certificate.result === 1)?_c('div',{staticClass:"cetificatePage__share"},[_c('ShareBlock',{attrs:{"shareText":_vm.shareText}})],1):_vm._e()],1),_c('div',{class:_vm.certificate.result === 1
                            ? 'certificateBox'
                            : 'certificateBox certificateBox--disabled'},[(_vm.certificate)?_c('vue-html2pdf',{ref:"html2Pdf",attrs:{"show-layout":false,"float-layout":true,"enable-download":true,"preview-modal":false,"paginate-elements-by-height":1400,"filename":'certificate_' + _vm.certificate.slug,"pdf-quality":2,"manual-pagination":false,"pdf-format":"a6","pdf-orientation":"landscape","pdf-content-width":"800px","html-to-pdf-options":{
                            margin: [5, 4],
                            filename: ("certificate_" + (this.certificate.slug) + ".pdf"),
                            image: {
                                type: 'jpeg',
                                quality: 1,
                            },

                            html2canvas: {
                                scale: 5,
                            },
                            jsPDF: {
                                format: 'a6',
                                orientation: 'landscape',
                            },
                        }}},[_c('div',{staticClass:"certificate certificate--pdf",attrs:{"slot":"pdf-content"},slot:"pdf-content"},[_c('CertificateCard',{attrs:{"certificateCardName":_vm.certificate.firstName + ' ' + _vm.certificate.lastName,"certificateCardCourse":_vm.certificate.courseName,"certificateCardDate":_vm.certificate.date2}})],1)]):_vm._e(),_c('div',{staticClass:"certificate"},[_c('CertificateCard',{attrs:{"certificateCardName":_vm.certificate.firstName + ' ' + _vm.certificate.lastName,"certificateCardCourse":_vm.certificate.courseName,"certificateCardDate":_vm.certificate.date2}})],1),_c('div',{staticClass:"backgroundZircon cetificateRightBg"})],1)])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }